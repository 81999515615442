<template>
  <div>
    <!-- header -->
    <header class="header hidden lg:block">
      <div class="wrapper">
        <div class="left">
          <div class="logo">
            <router-link class="site-title" to="/">
              <img src="@/assets/images/logo.png" />
            </router-link>
          </div>
          <div
            v-for="(category, name) in visibleNavLinks"
            :key="name"
            class="list-category"
          >
            <div class="category-header">
              <a
                class="category title relative"
                :class="{ active: isActive(category) }"
                @click="goTo(category.linkTo)"
              >
                <font-awesome-icon
                  :icon="category.icon"
                  v-if="category.icon"
                  style="margin-right: -2px"
                />
                {{ category.title }}
              </a>
            </div>
          </div>

          <div class="input-search">
            <div class="btn-icon">
              <i class="fas fa-search"></i>
            </div>
            <input
              ref="searchInputRef"
              v-model="searchKeyword"
              type="text"
              placeholder="Search for live events,  shows, news and more…"
              @change="handleSearch"
              @click.stop="searchFocused = true"
              @input="initAutoComplete"
            />
            <div v-if="searchFocused" class="popular-search">
              <p
                v-for="item in searchDropdownOptions"
                :key="item"
                class="popular-search__item"
                @mousedown.stop="searchWithKeyword(item)"
              >
                {{ item }}
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <a
            href="https://playpokergo.onelink.me/YuJc/nh56lr4w"
            target="_blank"
            class="header-link btn-play-now"
          >
            <svg
              class="icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              style="fill: white !important; width: 17px"
            >
              <path
                d="M7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zM197 159.6c-11.1-3-22.6 3.6-25.6 14.8l-7.2 26.9-20.4 76.1c-7 26 8.5 52.7 34.4 59.7s52.7-8.5 59.7-34.4l2.4-8.8c.1-.4 .2-.8 .3-1.1l17.7 30.7-12.1 7c-6.7 3.8-8.9 12.4-5.1 19s12.4 8.9 19 5.1l48.2-27.8c6.7-3.8 8.9-12.4 5.1-19s-12.4-8.9-19-5.1l-12.1 7-17.7-30.7c.4 .1 .8 .2 1.1 .3l8.8 2.4c26 7 52.7-8.5 59.7-34.4s-8.5-52.7-34.4-59.7l-76.1-20.4L197 159.6zM459.4 420.9c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1zm74.5-183.3L582 187.6c14-14.5 13.2-38.5-2.2-51.9c-14-11.7-34.5-9.5-46.9 3.2l-4.7 5.2-5-5.2c-12.5-12.7-33.2-15-46.7-3.2c-15.7 13.5-16.5 37.5-2.5 51.9l48.4 49.9c3 3.2 8.2 3.2 11.5 0z"
              />
            </svg>
            Play Now
          </a>
          <a
            href="https://shop.pokergo.com/"
            target="_blank"
            class="header-link"
          >
            <i class="fas fa-shopping-cart icon"></i>
            Shop Now
          </a>
          <div class="user-nav">
            <div class="btn-subscribe">
              <router-link
                :to="loggedIn ? '/select-plan' : '/register/select-plan'"
                v-if="!isPaidOrPending"
              >
                Subscribe
              </router-link>
              <router-link
                to="/account/change-plan/checkout"
                v-else-if="
                  this.subscription.store === 'stripe' &&
                    this.subscription.interval !== 'training_annual'
                "
              >
                Upgrade
              </router-link>
            </div>
          </div>
          <div @click="gotoLogin" v-if="!loggedIn" class="header-link div-link">
            <font-awesome-icon icon="right-to-bracket" class="icon" />
            Sign In
          </div>
          <div class="profile" v-if="loggedIn">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="../assets/images/account-icon.svg" width="28" />
                  <span class="name-profile" v-if="firstName != ''">
                    Hi, {{ firstName }}
                  </span>
                </span>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li class="dropdown-item" @click="goTo('/account')">
                    <i class="fas fa-user-circle" />
                    <span>Profile</span>
                  </li>
                  <li class="dropdown-item" @click="goTo('/account')">
                    <i class="far fa-chart-bar" />
                    <span>Subscription</span>
                  </li>
                  <li class="dropdown-item" @click="goTo('/my-list')">
                    <i class="fas fa-plus" />
                    <span>My List</span>
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li class="dropdown-item" @click="goTo('/logout')">
                    <i class="fas fa-sign-out-alt fa-fw" />
                    <span>Log Out</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <header
      class="header lg:hidden"
      :class="{ 'training-header': this.$route.path === '/training' }"
    >
      <router-link class="logo-mobile" to="/">
        <div class="img">
          <img src="@/assets/images/logo.png" />
        </div>
      </router-link>
      <div class="searchMobile" v-if="this.$route.path === '/search'">
        <div class="searchInput">
          <div class="btn-icon">
            <i class="fas fa-search"></i>
          </div>
          <input
            ref="mobileSearchInputRef"
            v-model="searchKeyword"
            type="text"
            placeholder="Search for live events,  shows, news and more…"
            @change="handleSearch"
            @click.stop="searchFocused = true"
            @input="initAutoComplete"
          />
        </div>
        <div v-if="searchFocused" class="popular-search">
          <p
            v-for="item in searchDropdownOptions"
            :key="item"
            class="popular-search__item"
            @mousedown.stop="searchWithKeyword(item)"
          >
            {{ item }}
          </p>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'

export default Vue.extend({
  name: 'Navbar',
  props: {
    nav: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      autoCompleteResults: [],
      viewType: 'PokerGO',
      searchKeyword: '',
      searchFocused: false,
      windowWidth: 900,
      isIe11: false,
      selectedCategory: '',
      navLinks: {
        home: {
          title: 'HOME',
          showSubLinks: false,
          linkTo: '/'
        },
        schedule: {
          title: 'SCHEDULE',
          showSubLinks: false,
          linkTo: '/schedule'
        },
        onDemand: {
          title: 'ON DEMAND',
          showSubLinks: false,
          linkTo: '/on-demand'
        },
        training: {
          title: 'PokerU',
          showSubLinks: false,
          linkTo: '/training',
          icon: 'graduation-cap'
        }
      },
      visibleMobileSubMenu: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isSubscriber', 'getViews', 'user']),
    ...mapState({
      email: (state) => state.user.email,
      subscription: (state) => state.user.subscription
    }),
    searchDropdownOptions() {
      if (this.searchKeyword) {
        return this.autoCompleteResults
      }
      return []
    },
    firstName() {
      return this.user.basic ? this.user.basic.first_name : ''
    },
    formatNameUser() {
      var email = this.email
      let user = email.substring(0, email.lastIndexOf('@'))

      return user
    },
    loggedIn() {
      return this.isAuthenticated
    },
    loginText() {
      if (!this.isAuthenticated) {
        return 'Sign In'
      }
      return 'Sign Out'
    },
    visibleNavLinks() {
      if (this.isAuthenticated) return this.navLinks
      return Object.fromEntries(
        Object.entries(this.navLinks).filter((l) => !l[1].onlyAuth)
      )
    },
    isMobile() {
      return this.windowWidth <= 1024
    },
    visibleSubMenu() {
      return (
        this.selectedCategory &&
        this.selectedCategory in this.navLinks &&
        this.navLinks[this.selectedCategory].subLinks
      )
    },
    visibleLinks() {
      return (Object.values(this.visibleSubMenu) || []).filter(
        (l) =>
          (!l.onlyForSignedIn || this.loggedIn) &&
          (!this.isMobile || !this.onlydesktopSearch) // Trying to not display search on menu
      )
    },
    isPaidOrPending() {
      const paypalPurchaseTime = localStorage.getItem('paypalPurchaseTime')
      if (this.isSubscriber) {
        // if paypal purchase success, remove item from localStorage
        if (paypalPurchaseTime) {
          localStorage.removeItem('paypalPurchaseTime')
        }
        return true
      }

      if (paypalPurchaseTime) {
        const currentTime = moment().unix()
        // if paypal purchase time is past more than 5 mins, remove the item and make 'subscribe' button visible
        if (currentTime - Number(paypalPurchaseTime) > 300) {
          localStorage.removeItem('paypalPurchaseTime')
          return false
        } else {
          return true
        }
      }

      if (this.isSubscriber) {
        return true
      }

      return false
    }
  },
  watch: {
    $route() {
      this.navigate(this.$route.path.split('/')[1])

      // reset searchKeyword from store value (which is '')
      this.searchKeyword = this.$store.state.other.searchKeyword
    },
    windowWidth() {
      const supportEl = document.querySelector('#launcher-frame')
      if (this.windowWidth <= 768) {
        if (supportEl) {
          supportEl.style.bottom = '50px'
          supportEl.style.right = '10px'
        }
      } else {
        if (supportEl) {
          supportEl.style.bottom = '22px'
          supportEl.style.right = '22px'
        }
      }
    }
  },
  async mounted() {
    this.searchKeyword = this.$route.query?.q

    document.body.addEventListener('click', this.hidePopup)

    this.navigate(this.$route.path.split('/')[1])
    this.windowWidth = window.innerWidth
  },
  methods: {
    setView(type) {
      this.viewType = type
    },
    gotoLogin() {
      if (this.$route.name === 'VideosId') {
        localStorage.setItem('loginRedirectURL', window.location.pathname)
      }
      this.$router.push({ path: '/login' })
    },
    hidePopup() {
      this.searchFocused = false
    },
    async initAutoComplete(e) {
      const payload = { query: e.target.value, maxResults: 10 }
      const { data } = await this.$store.dispatch(
        'search/getSearchAutoComplete',
        payload
      )
      this.autoCompleteResults = data
    },
    handleSearch() {
      this.hidePopup()
      if (this.searchKeyword) {
        this.$refs.searchInputRef?.blur()
        this.$refs.mobileSearchInputRef?.blur()

        this.$store.commit('setSearchKeyword', this.searchKeyword)
        this.$router.push(`/search?q=${this.searchKeyword}`)
      }
    },
    searchWithKeyword(keyword) {
      this.searchKeyword = keyword
      this.handleSearch()
    },
    goTo(path) {
      this.visibleMobileSubMenu = false
      if (this.$route.path === path) return

      // redirect to home to reload home page
      // if (path === '/') location.href = '/'

      this.$router.push({ path: path })
    },
    navigate(name) {
      this.selectedCategory = name
      if (name in this.navLinks && this.navLinks[name].linkTo) {
        this.goTo(this.navLinks[this.selectedCategory].linkTo)
      } else if (
        name in this.navLinks &&
        this.$route.path in this.visibleLinks
      ) {
        this.goTo(this.visibleLinks[this.$route.path].linkTo)
      }
    },
    isActive(category) {
      if (this.$route.path === '/preview' && category.linkTo === '/')
        return true

      return this.visibleSubMenu && category.subLinks
        ? this.$route.path in category.subLinks
        : this.$route.path === category.linkTo
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.hidePopup)
  }
})
</script>

<style lang="scss" scoped>
.user-nav {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  align-items: center;
  &.hidden {
    display: none;
  }
  a {
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $poker-accent;
    }
  }
}

.profile {
  height: 83px;
  line-height: 83px;
  margin-left: 15px;
  &-menu {
    height: 100%;
    .dropdown {
      height: 100%;
      .dropdown-toggle {
        padding: 0;
        color: #ffffff;
        font-size: 14px;
        width: 100px;
        .name-profile {
          text-transform: capitalize;
        }
      }
      ul {
        width: 159px;
        transform: translate3d(-40px, 70px, 0px) !important;
        li {
          font-size: 14px;
          display: block;
          line-height: 40px;
          color: #10181f;
          cursor: pointer;

          &:active {
            background: #ddd;
          }

          i {
            opacity: 0.4;
          }
          span {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}
.category-header {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 83px;
  line-height: 83px;
  .title {
    color: #ffffff;
    padding: 0px 10px;
    white-space: nowrap;
    &.active {
      color: #bb00e8;
      border-bottom: 2px solid #bb00e8;
    }
  }
}
.site-title {
  max-width: 130px;
  img {
    width: 100%;
  }
  h1 {
    padding: 0;
    margin: 0;
    font-size: 18px;
  }
}
// header
.header {
  width: 100%;
  height: 83px;
  line-height: 83px;
  //   background: gray;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .logo-mobile {
    .img {
      width: 165px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .wrapper {
    padding: 0 max(calc(50% - 730px), 1.5rem);
    justify-content: space-between;
    display: flex;
    .left {
      display: flex;
      float: left;
      flex-grow: 1;
      align-items: center;
      line-height: 83px;
      height: 83px;
      .logo {
        width: 156px;

        img {
          width: 100%;
        }
      }
      .list-category {
        display: flex;
        align-items: center;
        .category {
          cursor: pointer;
          a {
            text-decoration: none;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            font-family: 'UniSans-SemiBold';
          }
        }
      }
      .input-search {
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.1);
        height: 40px;
        padding: 10px;
        border-radius: 5px;
        position: relative;
        margin-left: 20px;
        flex-grow: 1;

        .btn-icon {
          height: 40px;
          line-height: 40px;
          i {
            color: #ffffff;
            font-size: 14px;
          }
        }
        input {
          height: 100%;
          font-size: 14px;
          background: none;
          color: #ffffff !important;
          border-style: none;
          width: 100%;
        }
        input[type='text'] {
          font-weight: 500;
          color: #ffffff !important;
          font-family: 'UniSans-SemiBold';
          font-size: 14px;
        }
        .focus-visible {
          outline: none !important;
        }
        ::placeholder {
          color: #ffffff;
          opacity: 0.6; /* Firefox */
        }

        .popular-search {
          position: absolute;
          top: 40px;
          left: 0;
          background: white;
          width: 100%;
          z-index: 9999;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          &__item {
            color: $poker-accent;
            font-size: 15px;
            line-height: 15px;
            padding: 20px;
            margin-bottom: 0;
            border-bottom: 1px solid grey;
            cursor: pointer;

            &:last-child {
              border: none;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      width: 560px;
      justify-content: flex-end;
      align-items: center;
      a {
      }
      .btn-subscribe {
        a {
          background-color: rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
          padding: 13px 23px;
          text-decoration: none;
          border: 2px solid #ffffff;
          border-radius: 4px;
          color: #ffffff;
          font-family: 'UniSans';
          font-size: 14px;
          letter-spacing: 0;
          margin: 0 16px;
        }
      }
      .btn-subscribe:hover {
        a {
          transition: 0.2s ease-in;
          background-color: $poker-accent !important;
        }
      }
      .header-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: white;
        font-size: 14px;
        line-height: 1rem;

        .icon {
          opacity: 0.4;
          margin-right: 8px;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 1075px) {
    .wrapper {
      padding: 0 max(calc(50% - 700px), 1.5rem);
      .left {
        width: 50%;
        .input-search {
          transition: 0.5s ease-in;
          display: none;
        }
      }
      .right {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .wrapper {
      padding: 0 max(calc(50% - 700px), 1.5rem);
      .left {
        width: 50%;
        .input-search {
          transition: 0.5s ease-in;
          display: none;
        }
      }
      .right {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 720px) {
    .wrapper {
      padding: 0 max(calc(50% - 700px), 1.5rem);
      .left {
        width: 50%;

        .dropdown {
          transition: 0.5s ease-in;
          display: none;
          .nl {
            box-shadow: none;
          }
        }
        .input-search {
          transition: 0.5s ease-in;
          display: none;
        }
      }
      .right {
        width: 50%;
        .header-link {
          transition: 0.5s ease-in;
          display: none;
          font-size: 14px;
        }
        .btn-login {
          a {
            padding: 10px 25px;
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .header {
      height: 65px;
      line-height: 65px;
    }
  }
}
@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    background: url('../assets/images/Background@2x.png') no-repeat;
    z-index: 999999;
  }
}
.searchMobile {
  padding: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  .searchInput {
    display: flex;
    align-items: center;
    width: 500px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 2rem;
    cursor: pointer;
    input {
      background: none;
    }
    input[type='text'] {
      color: white;
    }
    ::placeholder {
      color: white;
      opacity: 1; /* Firefox */
    }
    .btn-icon {
      align-content: flex-end;
      width: 10%;
      order: 2;
      margin-left: 10px;
      height: 45px;
      position: relative;
      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    input {
      width: 90%;
      order: 1;
    }
  }
  @media screen and (max-width: 768px) {
    .searchInput {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 420px) {
    .searchInput {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 1rem;
      ::placeholder {
        color: white;
        opacity: 1;
        font-size: 14px;
      }
      .btn-icon {
        font-size: 14px;
      }
    }
  }
  .popular-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.div-link {
  padding-right: 5%;
  cursor: pointer;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
  .two-buttons {
    margin: 0.7rem 1rem;
    width: 250px;
    border: 1px solid rgba(110, 103, 103, 0.25);
    border-radius: 5px;
    height: 40px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    button {
      justify-content: center;
      display: flex;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      padding-top: 1px;
      align-items: center;
      cursor: pointer;
      width: 50%;
      height: 100%;
      border: none !important;
      background-color: transparent;
      .icon {
        height: 22px;
        width: 22px;
        margin-right: 8px;
      }
      &.active {
        background-color: $poker-accent;
      }
      &:focus {
        outline: none !important;
      }
    }
  }
}

.training-header {
  position: fixed;
  background: none;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999999;
}

.btn-play-now {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
  padding: 13px 23px;
  text-decoration: none;
  border: 2px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  font-family: 'UniSans';
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 16px;

  &:hover {
    transition: 0.2s ease-in;
    background-color: $poker-accent !important;
  }
}
</style>
