var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "list-options" }, [
      _vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/account" } }, [
                _vm._v("Profile")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "li",
        [_c("router-link", { attrs: { to: "/search" } }, [_vm._v("Search")])],
        1
      ),
      _vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/my-list" } }, [
                _vm._v("My List")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/terms-and-conditions" } }, [
            _vm._v(" Terms and Conditions ")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/privacy-policy" } }, [
            _vm._v("Privacy Policy")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/about" } }, [
            _vm._v("About PokerGO")
          ])
        ],
        1
      ),
      _c(
        "li",
        [_c("router-link", { attrs: { to: "/faq" } }, [_vm._v("FAQ")])],
        1
      ),
      _vm._m(0),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/faq#contact" } }, [
            _vm._v("Contact Us")
          ])
        ],
        1
      ),
      _vm._m(1),
      _vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/logout" } }, [
                _vm._v("Sign out")
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/login" } }, [
                _vm._v("Sign in")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "account" }, [_vm._v(_vm._s(this.email))]),
      _c("div", { staticClass: "btn-subscribe mb-3 header-link" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://playpokergo.onelink.me/YuJc/nh56lr4w",
              target: "_blank"
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "icon",
                staticStyle: { fill: "white !important", width: "17px" },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 640 512"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zM197 159.6c-11.1-3-22.6 3.6-25.6 14.8l-7.2 26.9-20.4 76.1c-7 26 8.5 52.7 34.4 59.7s52.7-8.5 59.7-34.4l2.4-8.8c.1-.4 .2-.8 .3-1.1l17.7 30.7-12.1 7c-6.7 3.8-8.9 12.4-5.1 19s12.4 8.9 19 5.1l48.2-27.8c6.7-3.8 8.9-12.4 5.1-19s-12.4-8.9-19-5.1l-12.1 7-17.7-30.7c.4 .1 .8 .2 1.1 .3l8.8 2.4c26 7 52.7-8.5 59.7-34.4s-8.5-52.7-34.4-59.7l-76.1-20.4L197 159.6zM459.4 420.9c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1zm74.5-183.3L582 187.6c14-14.5 13.2-38.5-2.2-51.9c-14-11.7-34.5-9.5-46.9 3.2l-4.7 5.2-5-5.2c-12.5-12.7-33.2-15-46.7-3.2c-15.7 13.5-16.5 37.5-2.5 51.9l48.4 49.9c3 3.2 8.2 3.2 11.5 0z"
                  }
                })
              ]
            ),
            _vm._v(" Play Now ")
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "btn-subscribe" },
        [
          !_vm.isPaidOrPending()
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: _vm.loggedIn ? "/select-plan" : "/register/select-plan"
                  }
                },
                [_vm._v(" Subscribe ")]
              )
            : this.subscription.store === "stripe" &&
              this.subscription.interval !== "training_annual"
            ? _c(
                "router-link",
                { attrs: { to: "/account/change-plan/checkout" } },
                [_vm._v(" Upgrade ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "version" }, [
        _vm._v("Version " + _vm._s(_vm.version))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.pokergonews.com/press-releases/",
            target: "_blank"
          }
        },
        [_vm._v(" Press Releases ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "https://shop.pokergo.com/", target: "_blank" } },
        [_vm._v(" Shop Now ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }